
.search-dropdown-sec {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    font-size: 14px;
  }
  
  .search-body {
    display: flex;
    align-items: center;
    padding: 0.5em;
    cursor: pointer;
  }
  
  .search-body:hover {
    background-color: #f9f9f9;
  }
  
  .user-img-sec .user-img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  
  .search-content h5 {
    font-size: 16px;
    margin: 0;
  }
  
  .search-content p {
    margin: 0;
  }
  