.document-upload-sec{
    margin-top: 85px;
    font-family: 'Maven Pro', sans-serif;
}

.document-upload-sec .document-upload-box {
    background: var(--secondary-color);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.05);
    border: 5px solid rgba(0, 0, 0, 0.1);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.document-upload-sec .document-upload-box-1 {
    background: var(--secondary-color);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.05);
    border: 5px dashed rgba(0, 0, 0, 0.1);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-bottom: 0;
    cursor: pointer;
    flex-direction: column;
}

.document-upload-sec .sub-heading h4 {
    color: #808080;
    font-size: 1.5em;
    font-weight: 500;
    letter-spacing: 0.004em;
}

.document-upload-sec .sub-heading p {
    color: #c4c4c4;
    font-size: 1.2em;
    font-weight: 400;
    letter-spacing: 0.004em;
}

.doc-upload-img{
    width: 40em;
    height: 15em;
    object-fit: contain;
}

.receive-btn-blue {
    border: none!important;
    padding: 8px 30px!important;
    font-size: 13px!important;
    color: var(--secondary-color)!important;
    font-weight: 500!important;
    background: var(--primary-color)!important;
    border-radius: 1000px!important;
    -webkit-transition: opacity .15s ease,background-color .15s ease,-webkit-box-shadow .15s ease;
    transition: opacity .15s ease,background-color .15s ease,-webkit-box-shadow .15s ease;
    transition: opacity .15s ease,background-color .15s ease,box-shadow .15s ease;
    transition: opacity .15s ease,background-color .15s ease,box-shadow .15s ease,-webkit-box-shadow .15s ease;
    display: inline-block;
    white-space: nowrap;
    text-align: center;
    margin-top: 2em;
}

.receive-btn-blue:hover{
    background: var(--primary-color)!important; 
    color: var(--secondary-color)!important;
}

.doc-upload-img-1{
    /* max-width: 25em; */
    width: 15em;
    height: 15em;
    object-fit: contain;
}

.document-upload-sec .form-file{
    margin-bottom: 0;
}

.space-mg{
    height: 4em;
}


.document-upload-sec .form-control-file {
    height: 0;
    overflow: hidden;
    width: 0;
  }
  
.document-upload-sec .form-control-file + label {
    border: none;
    border-radius: 5px;
    color: var(--secondary-color);
    cursor: pointer;
    display: inline-block;
    font-family: 'Rubik', sans-serif;
    font-size: inherit;
    font-weight: 500;
    margin-bottom: 1rem;
    outline: none;
    padding: 1rem 50px;
    position: relative;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    vertical-align: middle;
  }

  .document-upload-sec .form-group {
    margin-bottom: 0!important;
  }

  .document-desc{
    color: #c4c4c4;
    font-size: 1.2em;
    font-weight: 400;
    letter-spacing: 0.004em;
  }
  