.search-component {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
}

.list__view__container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.list-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-left:10px; */
  cursor: pointer;
}

.select-button-container  {
  display: flex;
  align-items: center;
  gap: 10px;
}

.select-btn,
.Add-to-custom-favourite-list, 
.remove__from__list,
.color__tag__user {
  padding: 6px 19px;
  border: none;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 500;
  background-color: #6661e7;
  color: #ffffff;
}

.select-all-user {
  margin: 0;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 6px 19px;
  border: none;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 500;
  background-color: #6661e7;
  color: #ffffff;
  cursor: pointer;
}

.select-all-user > .select-all-input {
  margin: 0;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .search-component {
    margin-bottom: 2em;
    justify-content: center;
  }
}
