.ppv-content-request-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 7px;
}

.ppv-content-request-container .reject-ppv-request {
	margin: 25px 0 0 0;
	display: flex;
	flex-direction: column;
	justify-content: center;	
	gap: 5px;
}

.reject-ppv-request label {
	margin-bottom: 0px;
	font-size: 13px;
}

.reject-ppv-input {
	width: 100%;
	padding: 6px;
	font-size: 14px;
}

.ppv-request-input {
	width: 100%;
	margin-top: 25px;
	padding: 6px;
	font-size: 14px;
}

.budget-input-ppv {
	display: flex;
	flex-direction: column;
	gap: 4px;
}

.budget-input-ppv > .budget-input-range {
	padding: 5px 4px;
	font-size: 14px;
}

.send-request-btn {
	border: none;
	padding: 8px 12px;
	font-size: 14px;
	font-weight: 500;
	background-color: #6661e7;
	color: #fff;
	border-radius: 4px;
}

.ppv-request-erorr {
	color: rgb(255, 0, 0);
	font-size: 13px;
	font-weight: 400;
}