.new-search-sec{
    padding-top: 8em;
    padding-bottom: 4em;
}


.search-page-search-con{
    display: flex;
    gap: 10px;
    justify-content: space-between;
    /* align-items: center; */
    /* border: 1px solid black; */
}

.search-page-search-wrapper{
    display: flex;
    gap: 10px;
    /* border: 1px solid red; */
    justify-content: space-between;
    flex: 4;
    align-items: center;
    flex-direction: column;
}

.search-page-search-input-1{
    flex: 1;
    padding: 7px 10px;
    border: 1px solid var(--primary-color);
    font-size: 13px;
    border-radius: 5px;
    width: 100%;
}
.search-page-search-input-2{
    flex: 1;
    padding: 7px 10px;
    border: 1px solid var(--primary-color);
    font-size: 13px;
    border-radius: 5px;
}

.new-explore-search-select{
    flex: 1;
    width: 100%;
    
}


.new-explore-search-select select{
    width: 100%;
    padding: 7px;
    font-size: 13px;
    border: 1px solid var(--primary-color);
    border-radius: 5px;
}



.search-page-search-con ._2iA8p44d0WZ-WqRBGcAuEV {
    border: 1px solid var(--primary-color);
}
.search-page-search-con ._3vt7_Mh4hRCFbp__dFqBCI input {
    font-size: 13px;
}


 .new-explore-search-sec-2 ._3vt7_Mh4hRCFbp__dFqBCI input{
  font-size: 12px !important;
}


.searchPage_select_container{
    display: flex;
    align-items: center;
    gap: 12px;
}
.searchPage_select_explore{
    font-size: 13px;
    width: 135px;
    padding: 2px;
    height: 35px;
}



.new-explore-search-sec-2 ._3vt7_Mh4hRCFbp__dFqBCI ul{
  position: absolute;
  z-index: 10; 
  background-color: #fff;
  width: 100%;
}
