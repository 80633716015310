.new-feed-search {
	background: #ffffff;
	box-shadow: 0px 0px 7.20117px rgb(0 0 0 / 10%);
	padding: 5px;
}

.new-feed-search .form-control {
  border: 0 !important;
}

.new-feed-search .new-feeds-search-icon {
  width: 2em;
}

.new-feed-search .input-group-text {
  background-color: transparent !important;
  border: 0 !important;
}