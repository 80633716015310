.favorite-user-name {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	display: block;
	position: relative;
	font-size: 19px;
	line-height: 24px;
	padding-right: 22px;
	font-weight: 500;
	color: var(--tertiary-color);
}

.favorite-user-id {
	font-size: 14px;
	color: var(--quaternary-color);
	display: inline-block;
	vertical-align: top;
	max-width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}