.verification-sec{
    margin-top: 30px;
    margin-bottom: 4em;
}

.card-stripe-sec {
    grid-template-columns: repeat(2,1fr);
    display: grid;
    grid-gap: 1rem;
    gap: 1rem;
    margin: 2em 0;
}

.card-stripe-item {
    background-color: var(--secondary-color);
    border: 1px solid rgba(0, 0, 0, 0.05);
    box-shadow: 0 2px 10px rgb(0 0 0 / 15%);
    padding:1.5em;
    border-radius: 8px;
}

.modal-footer>*{
    margin:0;
}

.card-stripe-item .add-account-item .add-account-icon{
    width: 4em;
    margin-bottom: 1em;
}

.card-stripe-item .add-account-item{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.card-stripe-box .form-control {
  background-color: var(--secondary-color);
  border: 0!important;
  box-shadow: 0 2px 10px rgb(0 0 0 / 15%)!important;
  border-radius: 8px!important;
  padding-left: 1em!important;
}

.card-stripe-action-btn{
    display: flex;
    align-items: flex-end;
}

.card-stripe-action-btn .sv-clone{
    width: 1.5em;
} 

.card-stripe-box h5 {
    font-size: 1em;
    font-weight: 700;
    color: var(--tertiary-color);
    letter-spacing: 0.002em;
}

.card-stripe-box .form-control::placeholder{
    font-size: 14px;
}

.subscription-tip-ppv-tab .nav-link{
    color: #6c757d;
    font-weight: 600;
    font-size: 1.5em;
}

.subscription-tip-ppv-tab .nav-link.active{
    background-color: var(--primary-color)!important;
    color:var(--secondary-color)!important;
}

.card-stripe-list-box{
    background-color: var(--secondary-color);
    border: 1px solid rgba(0, 0, 0, 0.05);
    box-shadow: 0 2px 10px rgb(0 0 0 / 15%);
    padding: 1.5em;
    border-radius: 8px;
}

.height-auto{
    height: auto!important;
}

.credit-card-img {
    width: 3em;
}

.wallet-balence-amount {
    background-color: var(--secondary-color);
    border: 0!important;
    box-shadow: 0 2px 10px rgb(0 0 0 / 15%)!important;
    border-radius: 8px!important;
    padding: 1.5em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin:2em 0;
}

.wallet-balence-amount h4{
    color: #6c757d;
    margin-bottom: 0;
    font-size: 1.5em;
    font-weight: 500;
    letter-spacing: 0.002em;
}

.wallet-balence-amount p{
    font-size: 2em;
    font-weight: 800;
    color: var(--primary-color);
    margin-bottom: 0;
}

.conv-desc.desc{
    color: #828282;
    font-size: 1em;
    font-weight: 500;
}

.withdraw-money-btn {
    background: var(--primary-color)!important;
    border-color: var(--primary-color)!important;
    padding: 9px 30px!important;
    border-radius: 10px!important;
    text-align: center;
    color: var(--secondary-color);
    font-weight: 500!important;
    font-size: 13px!important;
    cursor: pointer;
    margin-bottom: 2em;
  }

  .withdraw-money-btn:hover,
  .withdraw-money-btn:focus,
  .withdraw-money-btn:active {
    background: var(--primary-color)!important;
    border-color: var(--primary-color)!important;
    color: #ffffff!important;
  }

  /*Verification Page CSS*/

  .verification-sec {
    font-family: 'Maven Pro', sans-serif!important;
  }

  .c-email {
    /* width: 40vw; */
    border-radius: 15px;
    overflow: hidden;
    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.05);
    box-shadow: 0 2px 10px rgb(0 0 0 / 15%);
    /* box-shadow: 0px 7px 22px 0px rgba(0, 0, 0, 0.1); */
    margin:auto;
    font-family: 'Maven Pro', sans-serif!important;
  }
  .c-email-header {
    background-color: var(--primary-color);
    width: 100%;
    height: 60px;
  }
  .c-email-header-title {
    font-size: 23px;
    height: 60px;
    line-height: 60px;
    margin: 0;
    text-align: center;
    color: var(--secondary-color);
    font-family: 'Maven Pro', sans-serif!important;
  }
  .c-email-content {
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    background-color: var(--secondary-color);
    padding: 15px;
  }
  .c-email-content-text {
    font-size: 17px;
    font-weight: 600;
    text-align: center;
    color: #343434;
    margin-top: 0;
    font-family: 'Maven Pro', sans-serif!important;
  }
  .c-email-code {
    grid-template-columns: repeat(6,1fr);
    display: grid;
    grid-gap: 1rem;
    gap: 1rem;
    /* background-color: #ddd; */
    border-radius: 40px;
    padding: 20px;
    text-align: center;
    font-size: 36px;
    letter-spacing: 10px;
  }
  .c-email-footer {
    width: 100%;
    background-color: var(--secondary-color);
  }

  .c-email-code .form-control{
      text-align: center;
  }

  .c-email-code  input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button{
    display: none;
  }
  .verify-btn {
    background: var(--primary-color)!important;
    border-color: var(--primary-color)!important;
    padding: 9px 30px!important;
    border-radius: 10px!important;
    text-align: center;
    color: var(--secondary-color);
    font-weight: 500!important;
    font-size: 13px!important;
    cursor: pointer;
    margin-top: 2em;
  }

  .verify-btn:hover,
  .verify-btn:focus,
  .verify-btn:active {
    background: var(--primary-color)!important;
    border-color: var(--primary-color)!important;
  }

  .verify-btn-sec{
      text-align: center;
  }

.verification-center{
  display: flex;
  justify-content: center;
}


.verificationCodeWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  overflow: hidden;
}

.verificationCodeWrapper .inputsWrapper {
  padding: 2em;
  background-color: transparent;
  border-radius: 20px;
}

.verificationCodeWrapper .inputsWrapper .code-input {
  display: flex;
  flex-direction: column;
  align-items: start;
}
.verificationCodeWrapper .inputsWrapper .code-label {
  margin: 0;
  margin-bottom: 16px;
}
.verificationCodeWrapper .inputsWrapper .code-inputs {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}
.verificationCodeWrapper .inputsWrapper .code-inputs input {
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  text-align: center;
  height: 50px;
  width: 50px;
  border-radius: 0;
  margin: 0 4px;
  border-bottom: 2px solid #a1abbd;
  font-size: 2em;
  padding: 0.25em;
  color: #343434;
  font-weight: 700;
}
.verificationCodeWrapper .inputsWrapper .code-inputs input:focus {
  outline: none;
}
/* .verificationCodeWrapper .inputsWrapper .code-inputs input:nth-child(3) {
  margin-right: 24px;
} */

.verificationCodeWrapper .inputsWrapper .footerLinks{
  display: flex;
  gap: 1em;
}

.verificationCodeWrapper .inputsWrapper .footerLinks .link-underlined{
  color: #007bff !important;
} 

@media (max-width: 576px) {
  .verificationCodeWrapper .inputsWrapper {
    padding: 1em;
  }

  .verificationCodeWrapper .inputsWrapper .code-inputs input {
    height: 35px;
    width: 35px;
  }
  .verificationCodeWrapper .inputsWrapper .code-inputs input:nth-child(3) {
    margin-right: 1em;
  }
  .verificationCodeWrapper .footerLinks {
    flex-wrap: wrap;
    justify-content: center;
  }
}