
.post-video-preview{
    width: 100%;
}
.canvas-container{
    position: absolute !important; 
    top: 0;
    z-index: 99;
}
.dropdownfilter_modal{
    width: 565px;
    height: auto;
    text-align: center;
    align-items: center;
    display: flex;
    background-color: #7f7f7f24;
    padding: 0 !important;
    justify-content: center;
}
.post-video-preview{
    width: 100%;
    max-height: 69.2vh;
}
.canvas-image {
    position: relative;
}
.show .card-body {
    display: block !important;
    visibility: visible !important; 
}
div .accordion .card-header{
    font-size: 14px;
    font-weight: 500;
    color: #676767;
    /* border: 2px solid #ededed !important; */
    /* border-radius: 7px !important; */
    /* margin-bottom: 7px !important; */
}
div .accordion .card-body{
    /* border: 2px solid #ededed; */
    /* border-radius: 7px; */
    font-size: 12px;
    /* margin-bottom: 10px; */
}
div .accordion .card{
    border: 1px solid #ededed !important;
    border-radius: 12px !important;
    margin-bottom: 10px;
    /* padding: 5px; */
}
.accordion > .card > .card-header::after{
    color: #aaaaaa;
    background-color: #ffffff;
    padding: 4px 7px 1px 7px;
    border-radius: 20px;
    border: 1px solid #d0cece;
    top: 1rem;
}
.filter{
    display: flex;
    gap: 16px;
    flex-direction: row;
    flex-wrap: wrap;
}
.filter .filter-card {
    width: 89px;
    height: 62px;
    background: #ededed;
    border-radius: 7px;
}
 .modal-footer{
    justify-content: center !important;

}
.accordion .modal-header{
    padding: 1em 1em !important;
}
.accordion .modal-header::before{
    border-top-left-radius: calc(0px - 0px) !important;
    border-top-right-radius: calc(0px - 1px) !important;
}
.accordion .modal-title.h4{
    font-size: 15px;
    font-weight: 500;
}
.canvas-image img{
    visibility: hidden;
}
.main-filter{
    display: flex;
    flex-wrap: wrap;
    grid-gap: 13px;
    gap: 5px;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
}
.main-filter .filter-option{
    width: 31%;
    height: 35px;
    border: 1px solid #ededed;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4%;
    line-break: anywhere;
    text-align: center;
    padding: 5px;
    cursor: pointer;
    font-weight: 600;
}
.main-filter .filter-option.active{
    border: 1px solid #6661e7;
    background-color: #6661e7;
    color: #fff;
    cursor: pointer;
}
.addtext .input-text button.btn.btn-primary{
    font-size: 14px;
    background: #6661e7;
    border-radius: 4px;
    padding: 5px 13px;
    border: 0px solid #6661e7;
}
.addtext .input-text input{
    width: 77%;
    height: 33px;
    border: 1px solid #ededed;
    border-radius: 4px;
    padding: 10px;
    font-size: 14px;
    background-color: #fbfbfb;
}
/* .addtext .input-text{
    display: flex;
    justify-content: space-between;
} */
.font-name, .font-size select{
    width: 90px;
    margin-top: 0;
    height: 38px;
    border-color: #a3a3a3;
    padding: 0px 7px;
    font-size: 14px;
    color: #a3a3a3;
    border-radius: 3px;
}

.fontnamesize{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}
.font-section{
    width: 68%;
}
.font-size{
    width: 30%;
}
.font-style{
    margin-top: 20px;
    display: flex;
    gap: 20px;
    justify-content: space-between;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: flex-start;
    align-items: flex-start;
}
.font-style div{
        width: 100%;
}
.text-dec {
    display: flex;
    /* gap: 37px; */
    justify-content: space-between;
}
.text-align{
    display: flex;
    justify-content: space-between;
}
.collapse .card-body {
    /* height: 51vh; */
    overflow-y: auto;
}
.font-style button {
    background: #ededed;
    border: 1px solid #ededed;
    color: #131313;
    padding: 6px 14px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    margin-right: 6px;
    cursor: pointer;
}

.font-style button.active {
    background-color: #8280e9;
    color: #ffffff;
}

.card-body .stickers {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 11px;
    justify-content: space-between;
}
.card-body .stickers div {
    background: #ededed;
    width: 90px;
    height: 90px;
    border-radius: 3px;
    cursor: pointer;
}
.card-body .stickers div img {
    max-width: 90px;
    max-height: 90px;
}
/* .color-picker{
    margin-top: 6px;
    border-radius: 32%;
}
.color-picker div div div {
    width: 42px !important;
    height: 35px !important;
    border-radius: 2px !important;
} */
.rectangle{
    /* max-height: 231px; */
    overflow: scroll;
    overflow-x: hidden;
}
div button.add-text-button:hover{
    background: #29275c  !important;
    border: 0px solid #29275c  !important;
}
.custom-filter, .custom-option{
    width: 100%;
}
.custom-filter h4{
    padding: 7px 0px;
    border: 1px solid #ddd;
    border-left: 0;
    border-right: 0;
    margin-bottom: 11px;
}
.sketch-picker {
    z-index: 9;
    position: fixed;
}
.custom-filter-option .toggle-mode .slider {
    background-color: #aaaaac;
}
.custom-filter-option textarea{
    font-size: 14px;
    padding: 9px;
    border: 1px solid rgb(120, 120, 120);
    border-radius: 4px;
    margin-bottom: 10px;
    height: 57px;
    width: 100%;
}
.custom-filter-option .toggle-mode input:checked + .slider {
    background-color: #6661e7;
}
.custom-filter-option .toggle-label{
    width: 100%;
}
.custom-filter-option .custom-option label{
    margin-bottom: 0;
    margin-top: 0;
}
.custom-filter-option .custom-option label.switch{
    margin-bottom: 0;
    margin-top: 0;
}
.custom-filter-option input[type=range] {
    height: 18px;
    -webkit-appearance: none;
    margin: 10px 0;
    width: 100%;
  }
  .custom-filter-option input[type=range]:focus {
    outline: none;
  }
  .custom-filter-option input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: #6661e7;
    border-radius: 1px;
    border: 0px solid #000000;
  }
  .custom-filter-option input[disabled]::-webkit-slider-runnable-track{
    background: #b0b0b0;
  }
  .custom-filter-option input[type=range]::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 1px solid #6661e7;
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: #8380e9;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -7px;
  }
  .custom-filter-option input[disabled]::-webkit-slider-thumb {
    background: #b0b0b0;
    border: 1px solid #b0b0b0;
  }
  .custom-filter-option input[type=range]:focus::-webkit-slider-runnable-track {
    background: #6661e7;
  }
  .custom-filter-option input[type=range]::-moz-range-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: #6661e7;
    border-radius: 1px;
    border: 0px solid #000000;
  }
  .custom-filter-option input[type=range]::-moz-range-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 1px solid #6661e7;
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: #8380e9dc;
    cursor: pointer;
  }
  .custom-filter-option input[type=range]::-ms-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  .custom-filter-option input[type=range]::-ms-fill-lower {
    background: #6661e7;
    border: 0px solid #000000;
    border-radius: 2px;
    box-shadow: 0px 0px 0px #000000;
  }
  .custom-filter-option input[type=range]::-ms-fill-upper {
    background: #6661e7;
    border: 0px solid #000000;
    border-radius: 2px;
    box-shadow: 0px 0px 0px #000000;
  }
  .custom-filter-option input[type=range]::-ms-thumb {
    margin-top: 1px;
    box-shadow: 0px 0px 0px #000000;
    border: 1px solid #6661e7;
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: #8380e9dc;
    cursor: pointer;
  }
  .custom-filter-option input[type=range]:focus::-ms-fill-lower {
    background: #6661e7;
  }
  .custom-filter-option input[type=range]:focus::-ms-fill-upper {
    background: #6661e7;
  }
  .color-picker .rwsr,.color-picker .prince{
    width: 32px !important;
    height: 32px !important;
    padding: 0 !important;
    font-size: 16px;
    border-radius: 50% !important;
  }
  .toggle-switch .switch {
    width: 40px;
  }

  /*  font famile dropdoun */

  .fontnamesize .btn-success:not(:disabled):not(.disabled).active,
  .fontnamesize .btn-success:not(:disabled):not(.disabled):active,
  .fontnamesize .show>.btn-success.dropdown-toggle{
    color: #000 !important;
    background-color: #ffffff !important;
    border-color: #000000 !important;
}
.fontnamesize .btn-success:not(:disabled):not(.disabled).active:focus,
.fontnamesize .btn-success:not(:disabled):not(.disabled):active:focus,
.fontnamesize .show>.btn-success.dropdown-toggle:focus{
    box-shadow: none;
}
.fontnamesize .btn-success {
    color: #000000 !important;
    background-color: #ffffff !important;
    border-color: #a3a3a3 !important; 
}
.fontnamesize .dropdown-menu.show {
    min-width: 31rem;
    height: 274px;
    overflow: auto;
    transform: translate(0px, 45px) !important;
    display: flex !important;
    flex-direction: row;
    flex-wrap: wrap;
}
.fontnamesize .btn-success.focus, .fontnamesize .btn-success:focus{
    box-shadow: none;
}
.custom-option .toggle-label{
    display: flex;
    align-items: center;
}
p#rangeValue{
    padding: 5px 12px;
    margin-left: 10px;
    border-radius: 5px;
    background: #ededed;
    width: 57px;
    text-align: center;
}
.addtext .custom-option .toggle-label {
    padding: 0;
}
.fontnamesize .dropdown-item{
    height: 50px !important;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
button#font-family{
    width: 199px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5em 1em !important;
}
.filletr-right-side{
    height: 69.2vh;
}
.fontnamesize.disable, .font-style.disable {
    opacity: 0.5;
    pointer-events: none;
}






div.css-tr4s17-option{
    background-color: white;
    color: black;
}

div.css-1jqq78o-placeholder{
    font-size: 13px;
}





.post-level-list-option-wrpapper .css-10wo9uf-option{
    display: flex !important;
    align-items: center !important;
    gap: 15px;
}


.post-level-list-option-wrpapper .css-d7l1ni-option{
    display: flex !important;
    align-items: center !important;
    gap: 15px;
}

.post-level-list-option-wrpapper .css-tr4s17-option{
    display: flex !important;
    align-items: center !important;
    gap: 15px;
}

.react-datepicker__input-container input[type="text"] {
    background-color: #ffffff;
    border: 0 !important;
    box-shadow: 0 2px 10px rgb(0 0 0 / 15%);
    border-radius: 8px;
    padding: 0.5em;
    font-size: 12px;
    min-height: 36px;
    position: relative;
    width: 100%;
}
.description_note_enter textarea{
    background-color: #ffffff;
border: 0 !important;
box-shadow: 0 2px 10px rgb(0 0 0 / 15%);
border-radius: 8px;
padding: 1em;
font-size: 12px;
}
