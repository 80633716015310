.m-l-10 {
  margin-left: 2rem;
}



/* Subscription access toggle btn */

.switchL {
  font-size: 10px;
  position: relative;
  display: inline-block;
  width: 3.5em;
  height: 2em;
}

/* Hide default HTML checkbox */
.switchL input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.sliderL {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  border: 1px solid #adb5bd;
  transition: .4s;
  border-radius: 30px !important;
}

.sliderL:before {
  position: absolute;
  content: "";
  height: 1.4em;
  width: 1.4em;
  border-radius: 20px !important;
  left: 0.27em;
  bottom: 0.2em;
  background-color: #adb5bd;
  transition: .4s;
}

input:checked + .sliderL {
  background-color: rgb(102, 97, 231);
  border: 1px solid rgb(102, 97, 231);
}
input:disabled + .sliderL {
  background-color: rgb(180 179 231);
  border: 1px solid rgb(202 200 249);
  cursor: default;
}

input:focus + .sliderL {
  box-shadow: 0 0 1px rgb(102, 97, 231);
}

input:checked + .sliderL:before {
  transform: translateX(1.4em);
  background-color: #fff;
}
input.switch-access-input {
  border: 1px solid #d2d6de;
  border-radius: 3px;
}
.perks-wrapper .switch-access-content-checkbox-wrapper input#no-limit-c {
  accent-color: rgb(102, 97, 231);
}
.modal-footer button.ftr-btn {
  background: #6661e7;
  color: #fff;
}
.modal-footer{
  justify-content: end !important;
}
.perks-wrapper .switch-access-content-checkbox-wrapper label.radio-btn {
  color: #6c757d;
}



.sub-level-list-wrapper .accordion > .card > .card-header::after{
  display: none;
  top: 0.39rem !important;
  
}