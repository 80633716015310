.list-header {
	display: flex !important;
	justify-content: space-between !important;
	align-items: center !important;
}

.btn-container {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
} 

.vertical-menu > .bookmarks-right-side > .btn-container > .list-submit-btn {
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
	border: 1px solid #6661e7;
	padding: 6px 26px;
	font-size: 0.80em;
	font-weight: 500;
	background-color: #6661e7;
	white-space: nowrap;
	color: #ffffff;
}

.field-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 4px;
}

.field-container > label {
	font-size: 13px;
	margin: 0;
}

.add-list {
	padding: 4px 5px;
	font-size: 13px;
	border: 1px solid rgb(210, 214, 222);
	border-radius: 3px;
}

.list-error {
	color: rgb(255, 0, 0);
	font-size: 13px;
	font-weight: 400;
}

.right-custom-container {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 14px;
}

.vertical-menu .nav-link:focus {
	outline: none;
}
.vertical-menu button.list-submit-btn.btn.btn-primary:hover, .modal-dialog .modal-footer button.ftr-btn:hover {
	background: #6661e7 !important;
	border: 1px solid #6661e7 !important;
}

.update-custome-model .accordion > .card > .card-header::after{
	top: 3px !important;
}


.search-list-con-main .new-feed-search-sec{
padding: 0.1em !important;
}

.search-list-con-main {
    display: flex;
    align-items: center;
	gap: 24px;
}

.list-filter-search-container {
    display: flex;
    gap: 6px;
	align-items: center;
	font-size: 12px;
}
.list-filter-search-container ._3vt7_Mh4hRCFbp__dFqBCI ul{
    z-index: 9999;
    position: absolute;
    width: 100%;
	background: #fff;
}

.list-filter-search-container ._2iA8p44d0WZ-WqRBGcAuEV{
	padding: 7px 6px 8px !important;
}

.color-tag-list-select {
    border: 1px solid #cccccc;
    border-radius: 4px;
    padding: 7px 6px 8px !important;
    min-height: 37px;
	font-size: 12px;
}

.list-filter-search-sort-container {
    display: flex;
    gap: 10px;
}

.list-sort-arrow-up{
  height: 21px;	
}
.list-sort-arrow-down{
  height: 15px;	
}


.list-filter-search-sort-container {
    display: flex;
    align-items: center;
}

.same-arrow{
	height: 17px;
}

.list-filter-search-container .css-b62m3t-container{

width: 130px !important;
}

.list-filter-search-container .css-13cymwt-control{

width: 130px !important;
}
.list-filter-search-container .css-t3ipsp-control{

width: 130px !important;
}


.search-dro-down-list-users{
	position: absolute !important;
    left: -5px !important;
    top: 52px !important;
}

.search-user-list-option-label-wrapper{
	display: flex !important;
	align-items: center;
	gap: 10px;
}

.error-serach-user{
	color: red;
    position: absolute;
    top: 47px;
    margin: 0px;
}

.list-friend-req-add-btn{
	position: relative;
}

.list-friend-req-add-btn .list-friend-req-red-dot{
	position: absolute;
    height: 9px;
    width: 9px;
    background: red;
    right: -3px;
    top: -3px;
    border-radius: 50%;
}