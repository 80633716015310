.upload-profile-picture-card{
    background: #ffffff;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 4em;
    box-shadow: 0 2px 10px rgb(0 0 0 / 15%);
    padding:4em;
    border-radius:2em;
}

.upload-profile-picture-card [type=file] {
    height: 0;
    overflow: hidden;
    width: 0;
  }
  
.upload-profile-picture-card [type=file] + label {
    background: transparent;
    border: none;
    border-radius:0.5em;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: "Rubik", sans-serif;
    font-size: inherit;
    font-weight: 500;
    margin-bottom: 1rem;
    outline: none;
    padding: 1rem 50px;
    position: relative;
    transition: all 0.3s;
    vertical-align: middle;
    border: 4px dashed rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 10px rgb(0 0 0 / 15%);
    width: 100%;
    margin-bottom: 0;
    text-align: center;
    height: 100%;
  }

  .upload-profile-picture-card .upload-profile-picture-img{
      width:100%;
      max-width: 20em;
      height: 20em;
  }

  .upload-profile-img-btn {
    background-color: #0091EA!important;
    color: #fff!important;
    border-color: #0091EA!important;
    padding: 0.8em 1.5em!important;
    font-weight: 600!important;
    font-size: 1.5em!important;
    border-radius: 2em!important;
  }
  .upload-profile-img-btn:hover,
  .upload-profile-img-btn:focus,
  .upload-profile-img-btn:active{
    background-color: #0091EA!important;
    color: #fff!important;
    border-color: #0091EA!important;
  }

  .upload-profile-info {
    border: 4px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 10px rgb(0 0 0 / 15%);
    padding:20px;
    border-radius:0.5em;
  }

  .upload-profile-picture-card .title {
    font-size: 1.8em;
    font-weight: 600;
    color: #8a96a3;
    margin-bottom: 1em;
  }

  .upload-profile-picture-card .desc{
    font-size: 1.2em;
    font-weight: 500;
    line-height: 1.8;
  }

  .edit-icon-sec {
    position: absolute;
    top: 15px;
    right: 15px;
  }

  .edit-icon-sec i{
      font-size: 2em;
      color:#8a96a3;
  }