.custom-modal-container .modal-dialog {	
	max-width: 950px;	
	margin: 1.75rem auto;
}

.custom-modal-container .modal-dialog .modal-body,
.custom-modal-container .modal-dialog .modal-body .col-md-12 {
	padding: 0 !important;
}

.custom-modal-container .new-single-page-left,
.custom-modal-container .new-single-page-right{
	padding: 0;
}

.pagination-container {
	margin-top: 0;
}

.next-arrow {
	rotate: 270deg;
}

.previous-arrow {
	rotate: 90deg;
}

.close-arrow {
	rotate: 135deg;
}

.next-btn,
.prev-btn,
.close-btn {
	outline: none;
	border: none;
	border-radius: 50%;
	height: 40px;
	width: 40px;
	background: silver
}

.close-btn {
	height: 35px;
	width: 35px;
}

.next-btn {
	position: absolute;
	top: calc(100% - 52%);
	right: -110px;
	z-index: 10;
}

.prev-btn {
	position: absolute;
	top: calc(100% - 52%);
	left: -110px;
	z-index: 10;
}

.close-btn {
	position: absolute;
	top: -10px;
	right: -110px;
	z-index: 10;
}