.tag-container,
.color__tag__input {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.field-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 4px;
}

.field-container .label-container {
	display: flex;
	justify-content: space-between;
}

.field-container > .label-container > label,
.color__tag__input > .field-container > label {
	font-size: 13px;
	margin: 0;
}

.field-container > #colorTag {
	cursor: pointer;
	border: none;
	background: none;
}

.add-tag {
	padding: 4px 5px;
	font-size: 13px;
	border: 1px solid rgb(210, 214, 222);
	border-radius: 3px;
}
