.new-feed-body-sec .awssld__content > div {
	height: 100%;
	width: 100%;
	display: contents;
  position: relative;
}

.btn__container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.btn__cta {
  font-size: 14px !important;
}

.flw__btn {
  border: 2px solid #6661e7 !important;
  background-color: #f9f9f9 !important;
  color: #000000 !important;
  border-radius: 4px;
}

.sbs__btn {
  background-color: #6661e7 !important;
  color: #ffffff !important;
  border-radius: 4px;
}

.unsbs__btn {
  background-color: #0e0e0e !important;
  border: 1px solid #0e0e0e !important;
  color: #ffffff !important;
  border-radius: 4px;
}

.unsbs__btn:hover {
  background-color: #DC3545 !important;
}

.sbs__btn:hover {
  background-color: #6661e7 !important;
}

.flw__btn:hover {
  border: 2px solid #6661e7 !important;
}

.feed-post-dropdown .dropdown-menu.show{

  position: absolute !important;
  inset: 0px 0px auto 18px !important;
}


.drop-d-m-forward-wrapper{
  display: flex;
  justify-content: space-around;
  gap: 4px;
}

.drop-d-m-forward.dropdown-menu.show {
  max-width: max-content !important;
  width: max-content !important;
  position: absolute !important;
  inset: 6px auto auto 259px !important;
}


.forward-post-dropdown .dropdown-menu.show:after{
  right: 288px !important;  
}
.forward-post-dropdown .dropdown-menu.show{
  border-top-right-radius: 10px;
  padding: 5px 5px;  
}

button.forward-post-dropdown-btn .btn-primary{
    color: none !important;
    background-color: none !important;
    border-color: none !important;
    padding: 0 !important;
    justify-content: flex-start !important;
}

.drop-d-social-icon{
  height: 36px;
}