.following-table-sec{
	/* background-color: #f5f9fd; */
	padding: 4em 0;
	margin-bottom: 2em;
	font-family: 'Maven Pro', sans-serif;
}

.following-table-sec .following-table {
	border: 1px solid rgba(0, 0, 0, 0.05);
	box-shadow: 0 2px 10px rgb(0 0 0 / 15%);
	padding: 2em;
	border-radius: 10px;
}

.following-table-sec .following-table td {
	vertical-align: middle;
}

.following-table .amount{
	font-size: 1.2em!important;
	font-weight: 600;
	color: #222222!important;
}

/* .following-table th {
	text-align: center;
} */