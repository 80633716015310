.field-checkbox-container {
	display: flex;
	flex-direction: column;
}

.field-checkbox-container .check-container > label {
	display: flex;
	font-size: 14px;
	gap: 5px;
	cursor: pointer;
}

.field-checkbox-container .check-container > label > input[type=checkbox] {
	margin: 0;
}

.field-checkbox-container > .no-list-container {
	color: rgb(121, 119, 119);
}

.list-error {
	color: rgb(255, 0, 0);
	font-size: 13px;
	font-weight: 400;
}