.single-profile-banner-img {
  width: 100%;
  height: 70vh;
  object-fit: cover;
  object-position: center;
}

.user-banner-container {
  position: relative;
}

.single-profile-toggle-dropdown {
  position: absolute;
  top: 8px;
  right: 4px;
}

.single-profile-toggle-dropdown .user-dropdown-dots {
  background: transparent !important;
  box-shadow: none !important;
}

.dropdown-menu.dropdown-menu-right.drp-dwn.dropdown-menu.show {
  transform: translate(-10px, 50px) !important;
}

.single-profile-toggle-dropdown .vertical-dots {
  rotate: 90deg;
}

.user-badge-container {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 27px;
  left: 20px;
  border-radius: 5px;
}

.user-badge-container p {
  margin-bottom: 0;
  font-size: 0.8em;
  font-weight: 400;
  padding: 4px 10px;
  color: var(--secondary-color);
}

.add-friend-btn {
  width: 100%;
  border: 1px solid var(--primary-color);
  padding: 8px 30px;
  font-size: 1.4em;
  color: var(--secondary-color);
  font-weight: 500;
  background: var(--primary-color);
  border-radius: 5px;
}

.single-profile-user-img-sec {
  margin-top: -6em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.single-profile-user-img {
  width: 15em;
  height: 15em;
  border-radius: 50%;
  border: 2px solid #fff;
  background-color: #fff;
  padding: 2px;
  object-fit: cover;
}

.border-red {
  width: 9em;
  height: 9em;
  border: 2px solid #ca3939 !important;
}

.single-profile-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.single-profile-info h4 {
  font-size: 18px;
  font-weight: 600;
  color: #8a96a3;
  margin-bottom: 0;
}

.single-profile-info h4 small {
  padding-left: 0.5em;
}

.single-profile-info p {
  font-size: 12px;
  color: rgba(138, 150, 163, 0.75);
  margin-bottom: 0;
  padding: 0.5em 0;
  margin-top: 0;
}

.single-pro-action-sec {
  display: flex;
  align-items: center;
}

.single-profile-sec .single-pro-action-sec.new-colum-action {
  flex-direction: column;
}

.flex-content-colum {
  margin-bottom: 2em;
}

.single-pro-action-sec li {
  margin-top: 0;
  margin-right: 1.5em;
  background: #fff;
  border: 1px solid #ccc;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 1em 2em;
  font-size: 1rem;
  border-radius: 30px;
  margin-bottom: 10px;
}

.single-pro-action-sec li a {
  color: #212529;
}

.single-pro-action-sec li.active {
  background-color: var(--primary-color);
}

.single-pro-action-sec li:hover {
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
}

.single-pro-action-sec li.active a {
  color: var(--secondary-color) !important;
}

.single-pro-action-sec li span {
  margin-right: 0.5em;
}

.flex-nav-link {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 0.5em;
}

.flex-nav-link span {
  color: #212529;
}

.flex-nav-link span + span {
  font-weight: 400;
}

.flex-nav-link-icon {
  width: 1.5em;
  height: 1.5em;
  margin-right: 0.6em;
}

.far.fa-file-archive {
  font-size: 20px;
  margin-right: 0.6em;
}

.single-profile-tab-sec .nav-pills .nav-link.active {
  color: #fff;
  background-color: transparent !important;
  border-bottom: 2px solid var(--primary-color);
  border-radius: 0;
}

.single-profile-tab-sec .nav {
  justify-content: center;
  border-bottom: 3px solid rgba(159, 163, 177, 0.07);
}

.single-profile-tab-sec .tab-content {
  padding: 4em 0;
}

.single-profile-card {
  border: 1px solid rgba(111, 111, 111, 0.125);
  padding: 2em;
  border-radius: 10px;
}

.single-profile-card h4 {
  font-size: 16px;
  font-weight: 600;
  color: #8a96a3;
  margin-bottom: 0.8em;
}

.single-profile-card p {
  font-size: 14px;
  color: rgba(138, 150, 163, 0.75);
}

.single-profile-card p i {
  margin-right: 0.5em;
}

.single-profile-card .badge-secondary {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.single-profile-card .badge-secondary i {
  margin-right: 0.2em;
}

.single-profile-post-card {
  border: 1px solid rgba(111, 111, 111, 0.125);
  border-radius: 10px;
}

.single-profile-post-user-img {
  width: 8em;
  height: 8em;
  border-radius: 50%;
  border: 2px solid #fff;
  background-color: #fff;
  padding: 2px;
  object-fit: cover;
}

.single-profile-post-info h4 {
  font-size: 18px;
  font-weight: 600;
  color: #8a96a3;
  margin-bottom: 0;
}

.single-profile-post-info h4 small {
  padding-left: 0.5em;
}

.single-profile-post-info p {
  font-size: 12px;
  color: rgba(138, 150, 163, 0.75);
  margin-bottom: 0.5em;
  margin-top: 0;
}

.single-profile-post-info p i {
  margin-left: 0.5em;
}

.single-profile-post-header {
  display: flex;
  align-items: center;
  padding: 2em;
}

.single-profile-post-info {
  padding-left: 1.5em;
}

.single-profile-post-info h4 img {
  margin-right: 0.5em;
}

.single-profile-post-info h4 span {
  font-weight: 400;
}

.single-profile-post-msg {
  font-size: 1.5em;
  padding: 1em 2em;
}

.single-profile-post-img-sec {
  position: relative;
}

.single-profile-post-img-sec::before {
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
}

.single-profile-post-img {
  width: 100%;
  height: 40em;
  object-fit: cover;
}

.single-profile-post-unlock-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 68%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 32%;
}

.single-profile-post-lock-img {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 45%;
}

.single-profile-post-lock-img i {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2em;
  height: 2em;
  border: 1px solid #dc354d;
  background-color: rgb(220 53 69 / 50%);
  border-radius: 50%;
  font-size: 3em;
  color: var(--secondary-color);
}

.single-profile-post-footer {
  padding: 1em 2em;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.single-profile-post-action-icons {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.single-profile-post-action-icons li {
  margin-right: 1.5em;
  margin-top: 0;
}

.single-profile-post-action-icons li img {
  margin-right: 0.2em;
}

.single-profile-post-action-icons li a {
  font-size: 18px;
  font-weight: 400;
  color: #212529;
}

.single-profile-post-action-icons li a:hover {
  color: #212529 !important;
}

.unlock-btn {
  background-image: linear-gradient(
    to right,
    #667eea,
    #6730e3,
    #6b8dd6,
    #6730e3
  );
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  color: #fff !important;
  cursor: pointer;
  padding: 9px 16px;
  /* min-height: 48px; */
  text-align: center;
  border: none;
  background-size: 300% 100%;
  border-radius: 50px;
  moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.unlock-btn:hover,
.unlock-btn:focus {
  color: var(--secondary-color) !important;
}

.verified-badge-sec {
  display: contents !important;
}

.verified-dating {
  width: 1.3em !important;
}

.btn-icon {
  width: 15%;
}

.social-share-sec {
  grid-template-columns: repeat(3, 1fr);
  display: grid;
  grid-gap: 2rem;
  grid-gap: 1rem;
  gap: 1rem;
  /* padding-top: 1em; */
}
.about-me-sec {
  display: contents !important;
  text-transform: capitalize;
}

.about-me-sec .col-md-8 {
  margin: 2em 0;
}

.single-profile-sec .post-header {
  display: block;
}

.social-icon {
  width: 30px;
  height: 30px;
}

.social-sec {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.post-sec {
  justify-content: space-between;
}

.profile-search-post {
  display: none;
}
