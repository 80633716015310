.tag-container .customize__btn {
	padding: 6px 19px;
  border: none;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 500;
  background-color: #6661e7;
  color: #ffffff;
}

.tag-container .custom__default__color {
  display: flex;
  flex-direction: column;
  /* gap: 8px; */
  height: 130px;
  overflow-y: scroll;
}

.default__color_field {
  display: flex;
  margin: 0px;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
}