.container-custom-favourite {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.custom-list-container, 
.favourite-list-container {
	display: flex;
	flex-direction: column;
	gap: 5px;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

.heading-list {
	margin: 0;
}

.custom-list-check,.favourite-list-checked {
	margin: 0;
	display: flex;
	align-items: center;
	gap: 5px;
	font-weight: 500;
	cursor: pointer;
}

.check-container {
	font-size: 14px;
}
