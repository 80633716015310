.main-post-forward-container{
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 10px 12px;
}

.post-forward-input{    
   width: 100%;
   padding: 5px 10px;
   border-radius: 14px;
   outline: none;
   border: 1px solid #dddddd;
   font-size: 13px;
}


.main-post-forward-users-container{
    /* border: 1px solid black; */
    /* height: 257px;
    overflow-y: scroll; */
}


.post_forward_user_card{
    display: flex;
    /* border: 1px solid black; */
    justify-content: space-between;
    align-items: center;
    margin-right: 10px;
    cursor: pointer;
}

.post_forward_user_card_details{
    display: flex;
    gap: 8px;
}



.main-post-forward-users-wrapper{
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 257px;
    overflow-y: auto;
}



.post_forward_user_card_user_info{
    display: flex;
    flex-direction: column;
    justify-content: center;    
}
.post_forward_user_card_user_info p{
  margin-bottom: 0px;   
}


.main-post-forward-chips-wrapper{
    display: flex;
    flex-wrap: wrap;
    /* display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-gap: 4px; */
    gap: 4px;
    width: 100%;
    /* border: 1px solid black; */
    /* height: 94px; */
    max-height: 94px;
    overflow: auto;
}


.main-post-forward-chips-wrapper .MuiChip-root{
    height: 23px;
    font-size: 1.1125rem;
    justify-content: space-between;
    width: fit-content;
}



.modal-footer button.forward-post-ftr-btn:disabled{
background: #4a46a7 !important ;
}



.post-forward-search-user-input-wrapper{
    position: relative;
}

.search-dropdown-post-forward-sec {
    position: absolute;
    top: 36px;
    z-index: 1000;
    display: none;
    width: 100%;
    font-size: 14px;
    text-align: left;
    list-style: none;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    display: block;
    transform: translate(0px, 0px);
    background-color: var(--secondary-color);
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.05);
    border: 5px solid rgba(0, 0, 0, 0.1);
    border-radius: 0.5em;
  }
  