.user-total-sub-list-wrapper div.css-10wo9uf-option, .user-total-sub-list-wrapper div.css-d7l1ni-option{
    display: flex;
    gap: 6px;
    align-items: center;
}


.user-total-sub-list-wrapper div.css-10wo9uf-option input{
    margin: 0;
}
.user-total-sub-list-wrapper div.css-10wo9uf-option label{
    margin: 0;
}



.user-total-sub-list-wrapper div.css-d7l1ni-option input{
    margin: 0;
}
.user-total-sub-list-wrapper div.css-d7l1ni-option label{
    margin: 0;
}




.active-chips-list-wrapper {
    display: flex;
    gap: 9px;
}



.active-chips-list {
    display: flex;
    border: 1px solid black;
    align-items: center;
    justify-content: space-between;
    border: 1px solid silver;
    border-radius: 9px;
    padding: 7px 7px;
    cursor: pointer;
    gap: 10px
}

.selected-active-tips{
    background-color: rgb(102 97 231);
    color: #fff;
}


.active-list-icon-text-wrapper {
    display: flex;
    align-items: center;
    gap: 5px;
}


.active-list-count-delete-btn{
    border: 1px solid silver;
    border-radius: 50%
}



.active-list-count-delete-btn {
    border: 1px solid silver;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    height: 13px;
    width: 13px;
    font-size: 7px;
}